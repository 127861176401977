<template>
  <div>
    <section class="header-haberler">
      <div class="formAlani">
        <div>
          <h3>{{ lang.insankaynaklari }}</h3>
          <h2>{{ lang.basvuruformu }}</h2>
          <div class="kart">
            <div class="beyaz">
              <h2>{{ hr.heading }}</h2>
              <hr>
              <div v-html="hr.desc">
              </div>
            </div>
          </div>
        </div>
        <form class="card-form">
          <div class="input">
            <input type="text" class="input-field" v-model="name" required/>
            <label class="input-label">{{ lang.adsoyad }}</label>
          </div>
          <div class="input">
            <input type="text" class="input-field" v-model="phone" required/>
            <label class="input-label">{{ lang.telefon }}</label>
          </div>
          <div class="input">
            <input type="text" class="input-field" v-model="mail" required/>
            <label class="input-label">{{ lang.email }}</label>
          </div>
          <div class="input">
            <input type="text" class="input-field" v-model="job" required/>
            <label class="input-label">{{ lang.mesleginiz }}</label>
          </div>
          <div class="input">
            <input type="text" class="input-field" v-model="ref" required/>
            <label class="input-label">{{ lang.referansbilgileri }}</label>
          </div>
          <br>
          <div class="action">
            <a @click="post" class="btn redWhite "><span>{{ lang.gonder }}</span></a>
          </div>
        </form>
      </div>
    </section>
    <div class="popup" :class="{'acc': popupText}" @click="popup">
      <div class="content">
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></i>
        <p>{{ popupText }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import iso from "../../axios";
import axios from "axios";

export default {
  data() {
    return {
      hr: [],
      lang:[],
      name: "",
      phone: "",
      mail: "",
      job: "",
      ref: "",
      popupText:null
    }
  },
  created() {
    iso.get('hresource')
        .then(response => {
          this.hr = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
        iso.get('langfix')
        .then(response => {
          this.lang = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods: {
    post: function () {
      const data = new FormData();
        data.append('name', this.name)
        data.append('phone', this.phone)
        data.append('mail', this.mail)
        data.append('job', this.job)
        data.append('ref', this.ref)

      axios.post('https://yonet.gifadwork.com/apipost/gesas/hresourcepost', data)
          .then(response =>{
            this.popupText = 'Başvurunuz Alınmıştır'
            this.name = null
            this.phone = null
            this.mail = null
            this.job = null
            this.ref = null
            window.scrollTo(0, 0);
          })
          .catch(error => console.log(error))
    },
    popup: function (){
      if(this.popupText != null){
        this.popupText = null
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style scoped>
.action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.formAlani {
  display: flex;
}

.formAlani > * {
  flex: 1;
}

form {
  padding-top: 30px;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}

.input {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  padding-top: 1.5rem;
}

.input + .input {
  margin-top: 1.5rem;
}

.input-label {
  color: #8597a3;
  position: absolute;
  top: 1.5rem;
  transition: 0.25s ease;
}

.input-field {
  border: 0;
  z-index: 1;
  background-color: transparent;
  border-bottom: 2px solid #eee;
  font: inherit;
  font-size: 1.125rem;
  padding: 0.25rem 0;
}

.input-field:focus, .input-field:valid {
  outline: 0;
  border-bottom-color: #af1116;
}

.input-field:focus + .input-label, .input-field:valid + .input-label {
  color: #af1116;
  transform: translateY(-1.5rem);
}

.header-haberler {
  position: relative;
  clear: both;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  text-align: left;
}

.header-haberler h3 {
  font-family: bukis;
  color: #af1116;
  font-size: 30px;
  margin: 8px 0px 8px 0px;
}

.header-haberler h2 {
  font-family: elyazi;
  font-size: 60px;
  margin: 8px 0px 8px 0px;
  color: #af1116;
}

.kart {
  width: 90%;
  margin-top: 30px;
}

.kart .beyaz h2 {
  font-family: 'Raleway', sans-serif;
  font-size: 22px;
  font-weight: bolder;
  color: #690c10;
}

.kart .beyaz hr {
  border-radius: 30px;
  width: 100%;
  height: 3px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #af1116;
}

.kart .beyaz b {
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  border-radius: 30px;
  margin: 5px;
  display: block;
  margin-top: 10px;
}

@media (max-width: 650px) {
  .formAlani {
    display: flex;
    flex-direction: column;
  }
}
</style>